import flatpickr from "flatpickr";
require('./bootstrap');
require('@fortawesome/fontawesome-free/js/all');

flatpickr(".datepicker", {
    dateFormat: "Y-m-d",
    locale: {
        firstDayOfWeek: 1 // start week on Monday
    }
});

$('[data-toggle="popover"]').popover();
